class DataGrade {
    static UNTRODDEN = 0;
    static TRODDEN = 1;
    static OK = 100;
    static MAYBE = 101;
    static WRONG = 102;
    static MISSING = 103;
    static ERROR = -1;
}

export default DataGrade;