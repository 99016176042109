export const Settings = Object.freeze({
    // USERNAME: "Tester01",
    // PASSWORD: "123+ABC",
    // STAGE: "https://api-entwicklung.teckpro.de/documentstore_web/api",
    //USERNAME: "Tester01",
    //PASSWORD: "123+ABC",
    //STAGE: "https://api-qs.teckpro.de/documentstore_web/api",
    USERNAME: "RundVUser",
    PASSWORD: "cV§}.NvdbNC:>U}x",
    STAGE: "https://api-scan.teckpro.de/documentstore_web/api",
    KFZSCHEIN: "KFZSCHEIN",
    THROW_TIMEOUT_AFTER: 40000
})