import React, {Component} from 'react';
import './footer-navigation.scss';
import {NavLink} from "react-router-dom"


class FooterNavigation extends Component {
    render() {

        return (
            <div className="footer-navigation d-flex justify-content-end">
                <div>
                    <NavLink to="/impressum">
                        Impressum
                    </NavLink>

                    &nbsp; | &nbsp;

                    <NavLink to="/datenschutzerklaerung">
                        Datenschutzerklärung
                    </NavLink>
                </div>
            </div>
        )
    }
}


export default FooterNavigation;
