import React, {Component} from 'react';
import Layout from "./views/layout/layout";
import ButtonScanapi from "./views/button-scanapi/button-scanapi"
import QrCode from "./components/qr-code/qr-code"
import QrCodeCubeQualities from "./components/qr-code-cube-qualities/qr-code-cube-qualities"
import {faQuestion} from "@fortawesome/pro-solid-svg-icons/faQuestion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/pro-solid-svg-icons/faExclamation";
import logo from "../src/assets/images/ruv-logo.jpg";
import FooterNavigation from "./views/footer-navigation/footer-navigation";
import {Route} from "react-router-dom";
import Impressum from "./views/impressum/impressum";
import {faTimes} from "@fortawesome/pro-light-svg-icons/faTimes";
import Datenschutzerklaerung from "./views/datenschutzerklaerung/datenschutzerklaerung";


class App extends Component {

    constructor(props) {
        super(props)

        this.state = {
            scanDataString: "",
            error: false
        };
    }

    goToStart() {
        this.setState({
            scanDataString: "",
            error: false
        })
    }

    displayAction() {
        const errorMessage = <div className="info-message error">
            <div className="d-flex justify-content-between">
                <div>
                    <h2><FontAwesomeIcon icon={faExclamation} title="Error"/>Huch</h2>
                </div>
                <div>
                    <a href="#" onClick={() => this.goToStart()}>
                        <FontAwesomeIcon className="closeIcon" icon={faTimes} title="schließen"/>
                    </a>
                </div>
            </div>

            <i>es ist ein Problem aufgetreten, mögliche Gründe, was tun?</i>
            <ul>
                <li>Prüfen Sie Ihre Internetverbindung (LTE oder WLAN empfohlen) und wiederholen Sie den Vorgang</li>
                <li>Ansprechpartner bei Problemen &nbsp;
                <a href="mailto:G_Vertriebsanwendungen_Komposit@ruv.de">G_Vertriebsanwendungen_Komposit@ruv.de</a>
                </li>
                <li>Wenn das alles nicht nützt bitte Foto des Fahrzeugschein unter Angabe des Handytyps und bei falscher Datenübertragung
                    mit Screen der Kontrollmaske aus R+V CONNECT an den Euch bekannten Gruppenbriefkasten senden.</li>
            </ul>
        </div>

        const landing = <>

            <div className="welcome-message text-center">
                <h1>Willkommen</h1>
                <div>beim Scannen des Fahrzeugscheins</div>
            </div>
            <div className="text-center">
                <ButtonScanapi
                    onGetScanData={string => this.setState({scanDataString: string, error: false})}
                    onThrowError={() => {
                        this.setState({scanDataString: "", error: true})
                    }}
                />
            </div>

            <div className="info-message">
                <h2><FontAwesomeIcon icon={faExclamation} title="Wie gehts"/> Tipps und Tricks</h2>
                <ul>
                    <li>
                        Bitte den ganzen Kfz-Schein fotografieren oder das vom Kunden zur Verfügung
                        gestellte Foto inklusive Ränder hochladen.
                    </li>
                    <li>
                        Sie können auch ein Foto von dem Fahrzeugschein (geöffnete Bilddatei) auf Ihrem
                        Bildschirm abfotografieren.
                    </li>
                    <li>
                        Farbige Kfz-Scheine werden besser erkannt als schwarz-weiß Kopien.
                    </li>
                    <li>
                        Der QR Code wird vor die Kamera des Laptops gehalten. Die Daten werden aus dem
                        QR-Code gelesen.
                    </li>
                    <li>
                        Im Berechnungsprogramm öffnet sich ein Popup Fenster, die ausgelesenen
                        Fahrzeug- und Halterdaten werden überprüft und können ggf. korrigiert werden.
                    </li>
                    <li>
                        Die Daten sind nun in der Kfz Fahrzeugdatenmaske innerhalb der Berechnung
                        vorbelegt. Die Halterdaten werden in den Antragsprozess ebenfalls übernommen und
                        vorbelegt.
                    </li>
                    <li>
                        Bei Bedarf die Hintergrundfarbe des Browser so einstellen, dass die Kamera in R+V
                        CONNECT die Ränder des QR-Codes erkennen kann.
                    </li>
                </ul>
            </div>


            <div className="info-message">
                <h2><FontAwesomeIcon icon={faQuestion} title="Wie gehts"/> Was passiert jetzt? So
                    einfach geht´s</h2>

                <i>Ich habe zusammen mit Ihnen soeben unser Berechnungsprogramm geöffnet.</i>
                <ol>
                    <li>
                        Ich fotografiere den Fahrzeugschein senkrecht oder nutze alternativ das mir von
                        Ihnen zugesandte Foto aus meiner Bilddatei.
                    </li>
                    <li>Aus dem Foto generieren wir einen QR Code (durch Wischen vergrößern). In diesem
                        Code sind Ihre Fahrzeugdaten vorhanden.
                    </li>
                    <li>
                        Das Foto Ihres Fahrzeugscheins wird nicht gespeichert und direkt nach Umwandlung in den QR-Code
                        gelöscht.
                        Es kann somit nicht wiederverwendet werden.
                    </li>
                    <li>
                        Im Berechnungsprogramm auf der Fahrzeugdatenmaske öffnen wir durch Klick auf
                        „QR Scan“ die Laptopkamera.
                    </li>
                    <li>Der QR Code wird vor die Kamera des Laptops gehalten. Die Daten werden aus dem
                        QR-Code gelesen und in das Berechnungsprogramm übernommen.
                    </li>
                    <li>
                        Im Berechnungsprogramm öffnet sich ein Popup Fenster, die ausgelesenen
                        Fahrzeugscheindaten werden überprüft und können ggf. korrigiert werden.
                    </li>
                    <li>
                        Die Daten sind nun in der Kfz-Fahrzeugdatenmaske innerhalb der Berechnung vorbelegt.
                        Die Halterdaten werden in den Antragsprozess ebenfalls übernommen und vorbelegt.
                    </li>
                </ol>
            </div>
        </>

        const qrPage = <>
            <div className="text-center">
                <div className="d-flex justify-content-between">
                    <div/>
                    <div className="align-self-center">
                        <h1 className="mb-4">QR Code</h1>
                    </div>
                    <div className="text-right pr-2">
                        <a onClick={() => this.goToStart()}>
                            <FontAwesomeIcon className="closeIcon" icon={faTimes} title="schließen"/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <QrCode message={this.state.scanDataString}/>
            </div>
            <div className="info-message">
                <h2><FontAwesomeIcon icon={faExclamation} title="Hinweis"/> Hinweis</h2>
                <ul>
                    <li>Halten Sie jetzt den QR Code vor die Kamera des Laptops (R+V CONNECT Scan-Button
                        gedrückt)
                    </li>
                    <li>QR – möglichst GROß</li>
                    <li>Ansprechpartner bei Problemen G_Vertriebsanwendungen_Komposit</li>
                    <li>Wenn das alles nicht nützt bitte Foto des Fahrzeugschein unter Angabe des Handytyps und bei falscher Datenübertragung
                        mit Screen der Kontrollmaske aus R+V CONNECT an den Euch bekannten Gruppenbriefkasten senden.</li>
                </ul>
            </div>
            <div className="info-message">
                <h2><FontAwesomeIcon icon={faExclamation} title="Wie gehts"/> Tipps und Tricks</h2>
                <ul>
                    <li>
                        Bitte den ganzen Kfz-Schein fotografieren/hochladen inklusive der Ränder.
                    </li>
                    <li>
                        Sie können auch ein Foto von dem Fahrzeugschein (geöffnete Bilddatei) auf Ihrem
                        Bildschirm abfotografieren.
                    </li>
                    <li>
                        Farbige Kfz-Scheine werden besser erkannt als schwarz-weiß Kopien.
                    </li>
                    <li>
                        Der QR Code wird vor die Kamera des Laptops gehalten. Die Daten werden aus dem
                        QR-Code gelesen und in das Berechnungsprogramm übernommen.
                    </li>
                    <li>
                        Im Berechnungsprogramm öffnet sich ein Popup Fenster, die ausgelesenen
                        Fahrzeugscheindaten werden überprüft und können ggf. korrigiert werden.
                    </li>
                    <li>
                        Die Daten sind nun in der Kfz Fahrzeugdatenmaske innerhalb der Berechnung
                        vorbelegt.
                    </li>
                    <li>
                        Bei Bedarf die Hintergrundfarbe des Browsers so einstellen,
                        dass die Kamera (in R+V CONNECT) die Ränder des QR Codes erkennen kann.
                    </li>
                </ul>
            </div>
        </>

        if (this.state.error) {
            return errorMessage
        } else {
            return !this.state.scanDataString ? landing : qrPage
        }
    }

    render() {

        return (
            <Layout>
                <div style={{display: "none"}} className="corner-ribbon top-left green">221014a</div>
                <div className="content mt-4">
                    <img className="Logo" src={logo} alt="Logo"/>
                    <Route exact path="/" render={() => this.displayAction() }/>
                    <Route path="/impressum" component={Impressum}/>
                    <Route path="/datenschutzerklaerung" component={Datenschutzerklaerung}/>
                </div>
                <FooterNavigation/>
            </Layout>
        )
    }
}

export default App;
