import DataGrade from "./DataGrade";
import Content from "./Content";

export default class ScanData {

    constructor(scanData) {
        this.scanData = this._makeScanKeyCompatible(scanData)
        console.log(this.asTeckproQrCodeString())
    }


    //
    // extract strasse,hausnummer,plz, ort from adresse
    //

    _STRASSE_UND_NUMMER = "STRNR"
    _NUR_STRASSE = "NURSTR"
    _PLZ_UND_ORT = "PLZORT"
    _NUR_ORT = "NURORT"
    _NUR_PLZ = "NURPLZ"
    _NUR_KENNZEICHEN = "NURKNZ"
    _KENNZEICHEN_UND_SAISON = "KNZSAI"
    _UNDEFINED = "UNDEF"


    _evaluate = (matchee, regexList) =>
        regexList.map(([key, pattern]) => {
            const matchArray = pattern.exec(matchee)
            return [key, matchArray]
        })

    _firstMatch = (matchee, regexList) => {
        const found = this._evaluate(matchee, regexList).find(([key, array]) => array !== null)
        return (found === undefined) ? [this._UNDEFINED, undefined] : found
    }


// Special mapping for strasse,hausnummer,plz,ort, saison

    _strasseRegexList = [
        [this._STRASSE_UND_NUMMER, /(\D{1,}?)\s*(\d.*?)$/],
        [this._NUR_STRASSE, /(\D{1,}?)$/],
    ]

    _ortRegexList = [
        [this._PLZ_UND_ORT, /(\d{1,5})\s*(\D.*)$/],
        [this._NUR_PLZ, /(\d{1,})$/],
        [this._NUR_ORT, /(\w.*)$/],
    ]

    _kennzeichenRegexList = [
        [this._KENNZEICHEN_UND_SAISON, /(.*)\(\s*(\d*)\s*-\s*(\d*)\s*\)\s*$/],
        [this._NUR_KENNZEICHEN, /(\D.*)$/]
    ]

    _errorContent = () => {
        return new Content({
        value: "",
        grading: this._mapQuality(DataGrade.ERROR)}); 
    }

    _mapAdresse = (scanDataValue) => {
        if (!scanDataValue) {
            return this._errorContent();
        } else {
            const {value, quality} = scanDataValue
            const adresseArray = value.split("--")
            if (adresseArray.length !== 2)
                return {
                    strasse: {value: "", grading: DataGrade.MISSING},
                    hausnummer: {value: "", grading: DataGrade.MISSING},
                    plz: {value: "", grading: DataGrade.MISSING},
                    ort: {value: "", grading: DataGrade.MISSING},
                }
            const [strasseUndNr, plzUndOrt] = adresseArray
            const [strasseUndNrKey, strasseUndNrMatches] = this._firstMatch(strasseUndNr.trim(), this._strasseRegexList)
            const [plzUndOrtKey, plzUndOrtMatches] = this._firstMatch(plzUndOrt.trim(), this._ortRegexList)

            return {
                ...this._mapStrasseUndNr(strasseUndNrKey, strasseUndNrMatches, quality),
                ...this._mapPlzUndOrt(plzUndOrtKey, plzUndOrtMatches, quality)
            }
        }
    }

    _mapStrasseUndNr = (key, matches, quality) => {
        const mappedQuality = this._mapQuality(quality)
        switch (key) {
            case this._STRASSE_UND_NUMMER:
                return {
                    strasse: {value: matches[1], grading: mappedQuality},
                    hausnummer: {value: matches[2], grading: mappedQuality}
                }
            case this._NUR_STRASSE:
                return {
                    strasse: {value: matches[1], grading: mappedQuality},
                    hausnummer: {value: "", grading: DataGrade.MISSING}
                }
            default:
                return {
                    strasse: {value: "", grading: DataGrade.MISSING},
                    hausnummer: {value: "", grading: DataGrade.MISSING}
                } 
        }
    }

    _mapPlzUndOrt = (key, matches, quality) => {
        const mappedQuality = this._mapQuality(quality)
        switch (key) {
            case this._PLZ_UND_ORT:
                return {
                    plz: {value: matches[1], grading: mappedQuality},
                    ort: {value: matches[2], grading: mappedQuality}
                }
            case this._NUR_ORT:
                return {
                    ort: {value: matches[1], grading: mappedQuality},
                    plz: {value: "", grading: DataGrade.MISSING}
                }
            case this._NUR_PLZ:
                return {
                    plz: {value: matches[1], grading: mappedQuality},
                    ort: {value: "", grading: DataGrade.MISSING}
                }
            default:
                return {
                    plz: {value: "", grading: DataGrade.MISSING},
                    ort: {value: "", grading: DataGrade.MISSING}
                }
        }
    }

    _mapKennzeichen = (scanDataValue) => {
        if (!scanDataValue) {
            return this._errorContent();
        } else {
            const {value, quality} = scanDataValue
            const mappedQuality = this._mapQuality(quality)
            const [kennzeichenKey, kennzeichenMatches] = this._firstMatch(value.trim(), this._kennzeichenRegexList)

            switch (kennzeichenKey) {
                case this._KENNZEICHEN_UND_SAISON:
                    return {
                        kennzeichen: {value: kennzeichenMatches[1], grading: mappedQuality},
                        saisonBeginn: {value: kennzeichenMatches[2], grading: mappedQuality},
                        saisonEnde: {value: kennzeichenMatches[3], grading: mappedQuality}

                    }
                case this._NUR_KENNZEICHEN:
                    return {
                        kennzeichen: {value: kennzeichenMatches[1], grading: mappedQuality},
                        saisonBeginn: {value: "", grading: mappedQuality},
                        saisonEnde: {value: "", grading: mappedQuality}
                    }
                default:
                    return {
                        kennzeichen: {value: "", grading: mappedQuality},
                        saisonBeginn: {value: "", grading: mappedQuality},
                        saisonEnde: {value: "", grading: mappedQuality}
                    } 
            }
        }
    }


    //
    // general mapping helper methods
    //

    _mapQuality = (quality) => {
        switch (quality) {
            case "-1" :
                return DataGrade.MISSING;
            case "0" :
                return DataGrade.OK;
            case "1" :
                return DataGrade.MAYBE;
            case "2" :
                return DataGrade.WRONG;
            default:
                return DataGrade.ERROR;
        }
    }


    _mapString = (scanDataValue) => {
        if (!scanDataValue) {
            return this._errorContent();
        } else {
            const {value, quality} = scanDataValue;
            return new Content({
                value: value,
                grading: this._mapQuality(quality)
            });
        }
    }

    _mapDate = (scanDataValue) => {
        if (!scanDataValue) {
            return this._errorContent();
        } else {
            const {value, quality} = scanDataValue;
            const [tag, monat, jahr] = value.split(".");
            const dateString = `${jahr}-${monat}-${tag}`;
            try {
                const date = (new Date(dateString)).toISOString().substring(0, 10);
                console.log(date)
                return new Content({
                    value: date,
                    grading: this._mapQuality(quality)
                });
            } catch {
                return this._errorContent()
            }
        }
    }

    //
    // create scandata json from scan api's data
    // (scandata json refers to questions' scan keys)
    //

    _makeScanKeyCompatible = (scanApiData) => {
        const {
            hsn, adresse, zulassungaufhalter, tsn, ort, identifikationsnummer, marke, nachname, erstzulassung,
            kennzeichen, beschreibung, vorname, plz, fahrzeugklasse1, fahrzeugklasse2, handelsname, kraftstoff,
            gesamtgewicht, gesamtplaetze, leistung, hubraum
        } = scanApiData;

        return {
            ...this._mapAdresse(adresse),
            ...this._mapKennzeichen(kennzeichen),
            herstellerNr: this._mapString(hsn),
            adresse: this._mapString(adresse),
            zulassungAufHalter: this._mapDate(zulassungaufhalter),
            typNr: this._mapString(tsn),
            altOrt: this._mapString(ort),
            altPlz: this._mapString(plz),
            fahrzeugId: this._mapString(identifikationsnummer),
            marke: this._mapString(marke),
            nachname: this._mapString(nachname),
            erstzulassung: this._mapDate(erstzulassung),
            vorname: this._mapString(vorname),
            fahrzeugklasse1: this._mapString(fahrzeugklasse1),
            fahrzeugklasse2: this._mapString(fahrzeugklasse2),
            handelsname: this._mapString(handelsname),
            kraftstoff: this._mapString(kraftstoff),
            gesamtgewicht: this._mapString(gesamtgewicht),
            gesamtplaetze: this._mapString(gesamtplaetze),
            leistung: this._mapString(leistung),
            hubraum: this._mapString(hubraum),
        }
    }

    //
    // String for QR-code generation
    //

    _qrCodeQuality = (scanEntry) => {
        switch (scanEntry.grading) {
            case DataGrade.MAYBE:
                return 1
            case DataGrade.ERROR:
                return 2
            case DataGrade.MISSING:
                return 2
            case DataGrade.OK:
                return 0
            default:
                return 2
        }
    }

    _qrCodeFromString = (scanEntry) => {
        return scanEntry.value + "|" + this._qrCodeQuality(scanEntry)
    }

    _qrCodeFromDate = (scanEntry) => {
        console.log(scanEntry)
        console.log(scanEntry.grading)
        if (scanEntry && scanEntry.grading != DataGrade.ERROR && scanEntry.grading != DataGrade.MISSING  ) {
            const [jahr, monat, tag] = scanEntry.value.split("-")
            const dateStr = `${tag}.${monat}.${jahr}`
            return dateStr + "|" + this._qrCodeQuality(scanEntry)
        }
        else {
            return "|" + this._qrCodeQuality(DataGrade.ERROR)
        }
    }

    asTeckproQrCodeString = () => {
        const {
            kennzeichen,
            saisonBeginn,
            saisonEnde,
            herstellerNr,
            typNr,
            erstzulassung,
            zulassungAufHalter,
            fahrzeugId,
            plz,
            fahrzeugklasse1,
            gesamtgewicht,
            leistung,
            nachname,
            vorname,
            strasse,
            hausnummer,
            ort,
            gesamtplaetze
        } = this.scanData

        const saisonBeginnStr = saisonBeginn.value.trim();
        const saisonEndeStr = saisonEnde.value.trim();
        const saisonStr = (saisonBeginnStr === "" || saisonEnde === "") ? "" : `${saisonBeginnStr}/${saisonEndeStr}`

        return ["2" ,
            this._qrCodeFromString(kennzeichen) ,
            saisonStr ,
            this._qrCodeQuality(kennzeichen) ,
            this._qrCodeFromString(herstellerNr) ,
            this._qrCodeFromString(typNr) ,
            this._qrCodeFromDate(erstzulassung) ,
            this._qrCodeFromDate(zulassungAufHalter) ,
            this._qrCodeFromString(fahrzeugId) ,
            this._qrCodeFromString(plz) ,
            this._qrCodeFromString(nachname) ,
            this._qrCodeFromString(vorname) ,
            this._qrCodeFromString(strasse) ,
            this._qrCodeFromString(hausnummer) ,
            this._qrCodeFromString(ort) ,
            this._qrCodeFromString(fahrzeugklasse1) ,
            leistung.value.replace(/\D/g, '') ,
            this._qrCodeQuality(leistung) ,
            this._qrCodeFromString(gesamtgewicht) ,
            this._qrCodeFromString(gesamtplaetze) ,
            "|-1"].join('|')
    }
}
