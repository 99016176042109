import React, {Component} from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode-react';
import "./qr-code.scss"


class QrCode extends Component {
    render() {
        const msg = this.props.showMessage && <div className='show-qr-message'>
            {this.props.message}
        </div>

        return <div className="qr-code">
            <QRCode value={this.props.message} bgColor="#FFFFFF" fgColor="#000000" size="200"/>
            {msg}
        </div>
    }
}

QrCode.propTypes = {
    message: PropTypes.string.isRequired,
    showMessage: PropTypes.bool
}

QrCode.defaultProps = {
    showMessage: false
}


export default QrCode
