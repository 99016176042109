import React, {Component} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "./pageBlocker.scss";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons"

class PageBlocker extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() {
        return(
            <div className="block-screen">
                <FontAwesomeIcon icon={faSpinner} spin={true} size={"8x"}/>
            </div>
        )
    }
}

export default PageBlocker;