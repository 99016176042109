import React, {Component} from 'react';
import './button-scanapi.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCircle, faCameraAlt} from "@fortawesome/pro-solid-svg-icons"
import Scanapi from "scanapi/esm"
import PageBlocker from "../page-blocker/page-blocker"
import ScanData from "../../model/ScanData"
import PropTypes from "prop-types"
import {Settings} from "../../consts"

class ButtonScanapi extends Component {
    constructor(props) {
        super(props)
        this.interval = null
        this.state = {
            pending: false,
        }

        this.start = this.start.bind(this)
    }

    componentWillUnmount() {
        clearInterval(this.interval); // Always clean up before unmounting
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })

    async start() {
        const timeoutString = "timeout"
        if (this.fileUpload && this.fileUpload.files && this.fileUpload.files[0]) {
            this.setState({
                pending: true
            })
            const file = this.fileUpload.files[0];
            const base64 = await this.toBase64(file)

            const prmScanapi = new Scanapi(Settings.STAGE)
            await prmScanapi.initScanapi(Settings.USERNAME, Settings.PASSWORD, [base64], Settings.KFZSCHEIN)
            const response = prmScanapi.analyze()


            const prmTimeout = new Promise(resolve => {
                this.interval = setTimeout(() => {
                    resolve(timeoutString);
                }, Settings.THROW_TIMEOUT_AFTER)
            })

            const race = Promise.race([
                response,
                prmTimeout
            ])

            race.then((oneOrOther) => {
                if (oneOrOther === timeoutString) {
                    console.log("Request timeout")
                    this.props.onThrowError()
                } else {
                    this.setState({
                        pending: false
                    })
                    let qrCode = new ScanData(oneOrOther).asTeckproQrCodeString()
                    this.props.onGetScanData(qrCode)
                }
            }).catch((error) => {
                console.log("Error: ", error)
                this.props.onThrowError()
            })
        }
    }

    render() {
        return (
            <div className="button-scanapi">
                <span className="fa-layers fa-9x cursor-pointer" onClick={() => this.fileUpload.click()}>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCameraAlt} inverse transform="shrink-7"/>
                </span>
                <input ref={input => this.fileUpload = input}
                       type="file"
                       accept="image/*;capture=camera"
                       onChange={() => this.start().catch(this.props.onThrowError)}
                       className="d-none"
                />
                {this.state.pending && <PageBlocker/>}
            </div>
        )
    }
}

ButtonScanapi.propTypes = {
    onGetScanData: PropTypes.func.isRequired,
    onThrowError: PropTypes.func.isRequired
}

ButtonScanapi.defaultProps = {}

export default ButtonScanapi
