import DataGrade from "./DataGrade";
import DataSource from "./DataSource";

class Content {
    constructor({
                    value="",
                    grading= DataGrade.UNTRODDEN,
    }) {
        this.value = value;
        this.grading = grading;
    }

    set(parameters) {
        return new Content({...this,  ...parameters})
    }

    static empty() {
        return new Content({});
    }
}

export default Content;