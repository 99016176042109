import React, {Component} from 'react';
import './impressum.scss';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/pro-solid-svg-icons";
import {NavLink} from "react-router-dom";
import {faTimes} from "@fortawesome/pro-light-svg-icons/faTimes";

class Impressum extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="impressum inhalt">

                <div className="text-center">
                    <div className="d-flex justify-content-between">
                        <div/>
                        <h1>Impressum</h1>

                        <div className="text-right pr-2">
                            <NavLink to="/">
                                <FontAwesomeIcon className="closeIcon" icon={faTimes}/>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="info-message">
                    <Row className="mb-3">
                        <Col xs={12} sm={4} className="font-weight-bold float-right">
                            teckpro AG
                        </Col>
                        <Col xs={12} sm={8}>
                            Spitalstraße 19-21<br/>
                            67659 Kaiserslautern
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12} sm={4} className="font-weight-bold">
                            Vertreten durch:
                        </Col>
                        <Col xs={12} sm={8}>
                            Vorstand: Michael Littig
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12} sm={4} className="font-weight-bold">
                            Aufsichtsrat:
                        </Col>
                        <Col xs={12} sm={8}>
                            Dr. Sabine Stürmer (Vorsitzende)<br/>
                            Prof. Dr. Ralf Korn (Stellvertretender Vorsitzender)<br/>
                            Dr. Jan-Christopher Kling
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12} sm={4} className="font-weight-bold">
                            Kontakt:
                        </Col>
                        <Col xs={12} sm={8}>
                            Tel.: (0631) 414 17-0<br/>
                            Fax: (0631) 414 17-17<br/>
                            E-Mail: <a href="mailto:office@teckpro.de">office@teckpro.de</a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12} sm={4} className="font-weight-bold">
                            Registereintrag:
                        </Col>
                        <Col xs={12} sm={8}>
                            AG Kaiserslautern<br/>
                            HRB-Nr.: 3796<br/>
                            USt.-IDNr.: DE 221 319 067
                        </Col>
                    </Row>
                </div>

                <div className="info-message">
                    <h3>Haftungsausschluss:</h3>

                    <div className="text-justify">Haftung für Inhalte<br/><br/>

                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                        Vollständigkeit und
                        Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
                        gemäß § 7 Abs.1 TMG
                        für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
                        10 TMG sind wir als
                        Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen
                        zu überwachen oder nach
                        Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                        Entfernung oder Sperrung der
                        Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                        diesbezügliche Haftung ist jedoch
                        erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
                        von entsprechenden
                        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </div>
                    <br/>

                </div>

            </div>
        )
    }
}


export default Impressum;
