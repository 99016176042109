import React, {Component} from 'react';
import './layout.scss';
import {Container} from "reactstrap"
import {HashRouter} from "react-router-dom"

class Layout extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <Container>
                <HashRouter>
                    {this.props.children}
                </HashRouter>
            </Container>
        )
    }
}


Layout.propTypes = {}

export default Layout;
