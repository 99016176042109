import React, {Component} from 'react'
import PropTypes from 'prop-types'
import "./qr-code-cube-qualities.scss"


class QrCodeCubeQualities extends Component {

    _cubeQualities = (message) => {
        const [version, ...rest] = message.split("|")
        return rest.filter((elem, index) => index % 2 === 1)
    }

    render() {
        return <div className="qr-code-cube-qualities">
            {this._cubeQualities(this.props.message).map((q, index) => {
                let cl
                switch (q) {
                    case "0":
                        cl = "qr-green-cube";
                        break;
                    case "1":
                        //TODO: -1 really?
                    case "-1":
                        cl = "qr-yellow-cube";
                        break;
                    case "2":
                        cl = "qr-red-cube";
                        break;
                    default:
                        cl = "qr-blue-cube";
                        break;
                }
                return <div key={index} className={cl}/>
            })}
        </div>
    }
}

QrCodeCubeQualities.propTypes = {
    message: PropTypes.string.isRequired
}

export default QrCodeCubeQualities
